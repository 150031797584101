import React, { useEffect, useState } from 'react';
import { intersectionBy } from 'lodash';
import PropTypes from 'prop-types';
import { useWithUserAndTenant } from 'cp-client-auth!sofe';
import { CpSelectMultiPills } from 'canopy-styleguide!sofe';

import styles from './multi-select-custom.styles.css';

export default function TeammemberSelect({
  possibleTeammembers,
  disabled,
  contentWidth,
  hasError,
  appendTo,
  teammembers,
  selectTeammembers,
  fullRoleOptions,
  overrideIsGroupData = false,
}) {
  const [user] = useWithUserAndTenant();
  const isGroupData = overrideIsGroupData || (!!fullRoleOptions?.length && !!possibleTeammembers?.length);

  const buildPossibleTeammembers = (teamMemberList, search) => {
    let teamMembers = [];
    teamMemberList.map(teamMember => {
      if ((search && teamMember.displayValue.toLowerCase().includes(search.toLowerCase())) || !search) {
        teamMembers.push({
          ...teamMember,
          displayValue: user?.id === teamMember.id ? `${teamMember.name} (me)` : teamMember.name,
        });
      }
    });
    return teamMembers;
  };

  const [formattedTeamMembers, setFormattedTeamMembers] = useState([]);
  const [teamMemberChoices, setTeamMemberChoices] = useState([]);
  const [assigneeSearch, setAssigneeSearch] = useState('');
  const [roleOptions, setRoleOptions] = useState(fullRoleOptions);

  useEffect(() => {
    setRoleOptions(fullRoleOptions);
  }, [JSON.stringify(fullRoleOptions)]);

  useEffect(() => {
    const builtTeamMembers = buildPossibleTeammembers(possibleTeammembers);
    setFormattedTeamMembers(builtTeamMembers);
    setTeamMemberChoices(builtTeamMembers);
  }, [possibleTeammembers]);

  const handleSearch = search => {
    setAssigneeSearch(search);
    setTeamMemberChoices(buildPossibleTeammembers(formattedTeamMembers, search));
    if (!!fullRoleOptions?.length) {
      setRoleOptions(
        fullRoleOptions.filter(
          role =>
            role.label.toLowerCase().includes(search.toLowerCase()) ||
            role.subName?.toLowerCase().includes(search.toLowerCase())
        )
      );
    }
  };

  const getSelectedTeammembers = () => {
    let builtTeamMembers = teammembers
      ? teammembers.map(teamMember => ({
          ...teamMember,
          displayValue: user?.id === teamMember.id ? `${teamMember.name} (me)` : teamMember.name,
        }))
      : [];

    return intersectionBy(
      [...formattedTeamMembers, ...(fullRoleOptions?.length ? fullRoleOptions : [])],
      builtTeamMembers,
      user => user && user.id
    );
  };

  const teamMemberChange = currentTeammembers => {
    let selectedMember;
    const longer = currentTeammembers.length > teammembers.length ? currentTeammembers : teammembers;
    const shorter = currentTeammembers.length < teammembers.length ? currentTeammembers : teammembers;
    longer.map(ctm => {
      let filtered = shorter.filter(tm => tm.id === ctm.id);
      if (!filtered.length) {
        selectedMember = ctm;
      }
    });
    selectTeammembers({
      currentTeammembers: currentTeammembers.map(tm => ({
        id: tm.id,
        [tm.type === 'role' ? 'label' : 'name']: tm.displayValue || tm.label,
        type: tm.type || 'user',
        subName: tm.subName,
        teams: tm.teams,
        users: tm.users,
      })),
      lastModifiedMember: selectedMember,
    });
  };

  const selectData = () => {
    const roleData = !assigneeSearch.trim() ? fullRoleOptions : roleOptions;
    if (isGroupData) {
      const groups = [
        { id: 1, name: 'Roles', data: roleData },
        { id: 2, name: 'Users', data: teamMemberChoices },
      ];
      return groups;
    } else {
      return !!fullRoleOptions?.length ? roleData : teamMemberChoices;
    }
  };

  const initialSelectedItems = getSelectedTeammembers();
  return (
    <div style={{ width: '100%', position: 'relative' }}>
      <CpSelectMultiPills
        data={selectData()}
        insertSearch
        placeholder={`Select ${isGroupData || !fullRoleOptions?.length ? 'assignee(s)' : 'role(s)'}`}
        onChange={teamMemberChange}
        searchOnChange={handleSearch}
        searchValue={assigneeSearch}
        value={initialSelectedItems}
        disabled={disabled}
        transformData={tm => ({
          ...tm,
          realName: tm.name || tm.label,
          name: tm.displayValue || tm.label,
        })}
        isGroupData={isGroupData}
        contentWidth={contentWidth || 'md'}
        triggerIsBlock
        hasError={hasError}
        className={hasError ? styles.hasError : ''}
        appendTo={appendTo || 'parent'}
      />
    </div>
  );
}

TeammemberSelect.propTypes = {
  possibleTeammembers: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  contentWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  hasError: PropTypes.bool,
  appendTo: PropTypes.object,
  teammembers: PropTypes.array.isRequired,
  selectTeammembers: PropTypes.func,
  fullRoleOptions: PropTypes.array,
  overrideIsGroupData: PropTypes.bool, // Used if you will ever pass fullRoleOptions or possibleTeammembers as empty arrays, but still want the dropdown to show the sections
};
