import React from 'react';
import Disposable from 'react-disposable-decorator';
import { partial, escapeRegExp } from 'lodash';
import Color from 'color';
import { successToast } from 'toast-service!sofe';
import { CpTooltip, CpInput } from 'canopy-styleguide!sofe';
import { UserTenantProps } from 'cp-client-auth!sofe';
import StatusCreation from './status-creation.component.js';
import { addStatus, removeStatus, unremoveStatus } from 'src/resources/statuses.resource.js';
import { handleError } from 'src/common/error.helper';
import styles from './status-dropdown.styles.css';

const permissions = {
  userCanCreateDeleteStatuses: 'tasks_status_create_delete',
};

// This component, and what it uses, are being exported
// and are shared to other projects
@UserTenantProps({ permissions })
@Disposable
export default class StatusDropdown extends React.Component {
  state = {
    create: false,
    statusSearchText: '',
  };

  componentDidMount() {
    this.setScroll();
  }

  render() {
    // as of Dec 2019, client request statuses are limited to max 4 and task statuses are >= 8
    const taskIsClientRequest = this.props.statuses.length < 5;
    const {
      statusNameMaxWidth,
      width,
      selectedStatus,
      alignDropdownRight,
      permissions,
      isOpen,
      positionAbsolute = true,
    } = this.props;
    const { statusSearchText, create } = this.state;
    const { userCanCreateDeleteStatuses } = permissions || {};
    const statuses = this.props.statuses.filter(
      status => (status.archived && selectedStatus?.id === status.id) || !status.archived
    );

    let filteredStatuses = statuses;
    if (statusSearchText) {
      filteredStatuses = statuses.filter(status => status.name.toLowerCase().includes(statusSearchText.toLowerCase()));
    }
    if (isOpen && !create) {
      this.searchInput?.focus({ preventScroll: true });
    }
    return (
      <div
        className={`cps-card__height-2 ${positionAbsolute ? styles.statusDropdown : ''}`}
        style={{ right: alignDropdownRight ? 0 : 'auto', width: width ? width : '248px' }}
        ref={ref => (this.dropdown = ref)}
      >
        <div ref={ref => (this.scrollBox = ref)} className={`${styles.scrollBox}`}>
          <div className={`${styles.statusFilterInput}`}>
            <CpInput
              isSearch
              onChange={statusSearchText => {
                this.setState({
                  statusSearchText,
                });
              }}
              onClear={() => {
                this.setState({
                  statusSearchText: '',
                });
              }}
              value={statusSearchText}
              className={`${styles.status}`}
              ref={ref => (this.searchInput = ref)}
              placeholder="find a status"
              data-search="search"
              onKeyDown={this.navigateWithArrowKeys}
              tabIndex="0"
            />
          </div>
          <hr className={`${styles.statusDropdownLine}`} />
          {filteredStatuses.map((status, index) => {
            const isLast = index === filteredStatuses.length - 1;
            const isSelected = status.id === selectedStatus?.id;
            return (
              <div
                ref={ref => {
                  if (isSelected) {
                    this.selectedStatus = ref;
                  }
                }}
                key={status.id}
                onClick={partial(this.updateTaskStatus, status.id)}
                data-test-class={`status-${index}`}
                className={`${styles.status}`}
                style={isSelected ? lightBackground(status.color) : {}}
                onKeyDown={e => this.navigateWithArrowKeys(e, status, isLast)}
                tabIndex="0"
              >
                <div className={`${styles.statusInfo}`}>
                  <CpTooltip text={status.name} delay={500}>
                    <div
                      style={{ background: status.color }}
                      className={`${styles.statusDot} ${status.name === 'No status' ? styles.noStatusBorder : ''}`}
                    />
                  </CpTooltip>
                  <div
                    className="cps-ellipsis status-name"
                    style={{ maxWidth: statusNameMaxWidth ? statusNameMaxWidth : 'calc(100% - 32px)' }}
                  >
                    {highlight(status.name, statusSearchText)}
                  </div>
                </div>
                <span
                  style={{
                    opacity: status.custom && userCanCreateDeleteStatuses ? 1 : 0,
                  }}
                  onClick={e => {
                    e.stopPropagation();
                    if (status.custom && userCanCreateDeleteStatuses) {
                      this.removeStatus(status.id);
                    }
                  }}
                  className={`cps-icon cps-icon-sm-neg ${styles.deleteX}`}
                />
              </div>
            );
          })}
        </div>
        {!taskIsClientRequest && userCanCreateDeleteStatuses && !statusSearchText && (
          <div
            onClick={() => {
              this.setState({
                create: true,
              });
            }}
            className={`${styles.status}`}
            data-add="add"
            onKeyDown={this.navigateWithArrowKeys}
            tabIndex="0"
          >
            <div className={`${styles.statusInfo}`}>
              <div style={{ background: '#DFDFDF' }} className={`${styles.statusDot}`} />
              <div className={`cps-light-gray`} style={{ whiteSpace: 'nowrap' }}>
                Add custom status
              </div>
            </div>
            <span
              style={{
                opacity: 0,
              }}
              className={`cps-icon cps-icon-close`}
            />
            {create && (
              <StatusCreation
                close={() => {
                  this.setState({
                    create: false,
                  });
                }}
                createStatus={this.createStatus}
              />
            )}
          </div>
        )}
      </div>
    );
  }
  updateTaskStatus = id => {
    this.props.updateTaskStatus(id);
    this.props.close();
    this.setState({
      statusSearchText: '',
    });
  };
  removeStatus = id => {
    this.props.cancelWhenUnmounted(
      removeStatus(id).subscribe(statuses => {
        this.props.updateStatuses(statuses);
        successToast('Status removed', 'Undo', () => {
          this.unremoveStatus(id);
        });
      }, handleError)
    );
  };
  unremoveStatus = id => {
    this.props.cancelWhenUnmounted(
      unremoveStatus(id).subscribe(statuses => {
        this.props.updateStatuses(statuses);
      }, handleError)
    );
  };
  createStatus = status => {
    this.props.cancelWhenUnmounted(
      addStatus(this.props.userId, status).subscribe(statuses => {
        this.props.updateStatuses(statuses);
      }, handleError)
    );
  };
  setScroll = () => {
    if (this.selectedStatus && this.scrollBox) {
      const selectedRect = this.selectedStatus.getBoundingClientRect();
      const scrollRect = this.scrollBox.getBoundingClientRect();
      if (scrollRect.bottom < selectedRect.bottom) {
        this.scrollBox.scrollTop = selectedRect.bottom - scrollRect.bottom;
      }
    }
  };

  navigateWithArrowKeys = (e, status, isLast) => {
    const key = e.key;
    const currentTarget = e.currentTarget;
    const keys = {
      ENTER: 'Enter',
      ARROW_DOWN: 'ArrowDown',
      ARROW_UP: 'ArrowUp',
      TAB: 'Tab',
    };
    const isSearchField = currentTarget.dataset?.search?.includes('search');
    const isStatusField = currentTarget.dataset?.testClass?.includes('status-');
    const isCreateField = currentTarget.dataset?.add === 'add';
    const isFirst = currentTarget.dataset?.testClass?.includes('status-0');

    if (key === keys.TAB) {
      this.setState({
        statusSearchText: '',
      });
      this.props.close();
    }
    if (key === keys.ENTER) {
      if (isStatusField) {
        this.updateTaskStatus(status?.id);
      }
      if (isCreateField) {
        this.setState({
          create: true,
        });
      }
    }
    if (key === keys.ARROW_DOWN || key === keys.ARROW_UP) {
      e.preventDefault(); // disable default page scrolling on key up and down events
    }
    if (key === keys.ARROW_DOWN) {
      if (isSearchField) {
        currentTarget.parentElement.parentElement.parentElement.nextSibling.nextSibling.focus();
      } else if (isLast) {
        currentTarget.parentElement.nextSibling.focus();
      } else {
        currentTarget.nextSibling?.focus();
      }
    }
    if (key === keys.ARROW_UP) {
      if (isCreateField) {
        currentTarget.previousSibling.children[currentTarget.previousSibling.children.length - 1].focus();
      } else if (isFirst) {
        currentTarget.previousSibling.previousSibling.children[0].children[0].focus();
      } else {
        currentTarget.previousSibling?.focus();
      }
    }
  };
}

function lightBackground(color) {
  const newColor = darkenLoop(Color(color).lighten(0.8));
  return {
    background: newColor.hex(),
  };
}

function darkenLoop(color) {
  if (color.lightness() > 95) return darkenLoop(color.darken(0.1));
  else return color;
}

function highlight(text = '', search) {
  let sanitizedHighlight = search?.toLowerCase().trim();
  if (!search || search.trim().length === 0 || !text.toLowerCase().includes(sanitizedHighlight)) {
    return <span>{text}</span>;
  }
  const sections = text.replace(new RegExp(escapeRegExp(search), 'ig'), '*$&*').split('*');

  return (
    <span>
      {sections.map((segment, index) => {
        if (sanitizedHighlight === segment.toLowerCase()) {
          return (
            <strong
              style={{
                color: 'var(--cp-color-app-primary)',
                backgroundColor: 'transparent',
              }}
              key={index}
            >
              {segment}
            </strong>
          );
        } else return <span key={index}>{segment}</span>;
      })}
    </span>
  );
}
